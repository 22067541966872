import React, {useState} from "react";
import { Link } from "react-router-dom";
//import logo from "../../../assets/images/brand-logo.svg";
import stock from "../../../assets/images/stock-1.png";
import {AuthServices} from "../../../services/client/Auth";
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const navigate = useNavigate();
    const [formFiled,setFormFiled] = useState({email:"",password:"",confirmpassword:""});
    const [error,setError] = useState("");

    const handleChange =(name,e)=>{
        
        setFormFiled(previousState => ({
            ...previousState,
            [name]: e.target.value,
          }));
    }

    const  handleSubmit  = async ()=>{
        try {
            const result=await AuthServices.login({email:formFiled.email,password:formFiled.password});
            localStorage.setItem("user", JSON.stringify(result.data));
            navigate("/client")
          } catch (error) {
            setError(error.response.data.data.errors);
          }
    }


    return (
      

            <main class="app-auth">

                <div class="container">
                    <header class="d-flex flex-wrap justify-content-center py-3 mb-4">
                        <Link to="" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none"> 
                            HTML POC
                        </Link>
                        <ul class="nav nav-pills">
                            <li class="nav-item"><Link to="/" class="btn btn-secondary" aria-current="page" contenteditable="false" style={{"cursor": "pointer;"}}>Sign up </Link></li>
                        </ul>
                    </header>

                    <div class="row align-items-center">
                       <div class="col-sm-6">
                            <div class="reviews">
                            <h2><i class="bi bi-quote"></i> World's simplest skill-based hiring platform, highly recommended for anyone who dont want to make hiring mistakes.</h2>
                            <div class="author mt-4">
                                <img src={stock} width="50" alt="" class="rounded me-3" /> <span>Jane Doe — Founder Alpha Inc</span>
                            </div>
                            </div>            
                        </div>
                        <div class="col-sm-4 offset-sm-2 px-4">
                            <h4>Log in to your account!</h4>
                            <p>Dont have an account? <Link to="/">Sign up</Link></p>
                            <div className="validation-error mt-2">{error?.common}</div>
                            <div class="mt-5">
                                <div class="mb-3">
                                    <label for="" class="form-label">Email</label>
                                    <input placeholder="Enter your company email" onChange={(e)=>handleChange("email",e)} value={formFiled.eamil} type="text" class="form-control" id="" placeholder="Enter your company email" />
                                    <div className="validation-error mt-2">{error?.email}</div>
                                </div>
                                <div class="mb-3">
                                    <label for="" class="form-label">Password</label>
                                    <input placeholder="Password" onChange={(e)=>handleChange("password",e)} value={formFiled.password} type="password" class="form-control" id="" />
                                    <div className="validation-error mt-2">{error?.password}</div>
                                </div>
                               

                                <button type="button" onClick={handleSubmit} class="btn btn-primary btn-xl w-100 mt-4">Log in</button>
                                <div id="emailHelp" class="form-text mt-4"><Link to="">Forgot password?</Link> </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
       
    );
}
export default Login 