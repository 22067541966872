import instance from "../../wrapper/withAxios";

export const QuestionServices = {
    getList,
    save,
    dropDown,
    updateStatus,
    getType,
    getQuestionByid,
    update
};

async function getList(payloads) {
  
    try {
        const response = await instance.post(process.env.REACT_APP_BASE_URL+`superadmin/question/get-list`, payloads);
        const result = await response;
        return result;
    } catch (error) {
        throw error;
    }
}

async function dropDown(payloads) {
  
    try {
        const response = await instance.post(process.env.REACT_APP_BASE_URL+`superadmin/question/drop-down`, payloads);
        const result = await response;
        return result;
    } catch (error) {
        throw error;
    }
}

async function save(payloads) {
  
    try {
        const response = await instance.post(process.env.REACT_APP_BASE_URL+`superadmin/question/save`, payloads);
        const result = await response;
        return result;
    } catch (error) {
        throw error;
    }
}

async function updateStatus(payloads) {
  
    try {
        const response = await instance.post(process.env.REACT_APP_BASE_URL+`superadmin/question/update-status`, payloads);
        const result = await response;
        return result;
    } catch (error) {
        throw error;
    }
}


async function getType(payloads) {
  
    try {
        const response = await instance.post(process.env.REACT_APP_BASE_URL+`superadmin/question/get-type`, payloads);
        const result = await response;
        return result;
    } catch (error) {
        throw error;
    }
}


async function getQuestionByid(payloads) {
  
    try {
        const response = await instance.post(process.env.REACT_APP_BASE_URL+`superadmin/question/edit`, payloads);
        const result = await response;
        return result;
    } catch (error) {
        throw error;
    }
}


async function update(payloads) {
  
    try {
        const response = await instance.post(process.env.REACT_APP_BASE_URL+`superadmin/question/update`, payloads);
        const result = await response;
        return result;
    } catch (error) {
        throw error;
    }
}

