// userSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getQuestionApi,
  removeQuestionApi,
  getQuestionCountApi
} from "./questionAPI";

export const getQuestion = createAsyncThunk(
  "task/getLibrary",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getQuestionApi(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const getQuestionCount = createAsyncThunk(
  "task/getQuestionCount",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getQuestionCountApi(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const removeQuestion = createAsyncThunk(
  "task/removeQuestion",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await removeQuestionApi(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const questionSlice = createSlice({
  name: "question",
  initialState: {
    questions: [],
    loading: true,
    firstLoading: true,
    error: null,
    search:"",
    validationErrors: {},
    questionsDetails:[]
    
  },
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    
  },
  extraReducers: (builder) => {
    builder
      // Handle fetch Library
      .addCase(getQuestion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getQuestion.fulfilled, (state, action) => {
        state.loading = false;
        state.questions = action.payload;
        state.refresh = false;
        state.firstLoading= false;
      })
      .addCase(getQuestion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.refresh = false;
      })

      // Handle  remove
      .addCase(removeQuestion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removeQuestion.fulfilled, (state, action) => {
        state.loading = false;
        state.refresh = true;
      })
      .addCase(removeQuestion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Handle fetch question count
      .addCase(getQuestionCount.pending, (state) => {
        state.error = null;
      })
      .addCase(getQuestionCount.fulfilled, (state, action) => {
        state.questionsDetails = action.payload;
      })
      .addCase(getQuestionCount.rejected, (state, action) => {
        state.error = action.payload;
      })




  },
});
export const { setSearch } = questionSlice.actions;

export default questionSlice.reducer;
