import instance from "../../wrapper/withAxios";

export const SkillServices = {
    getList,
    save,
    dropDown,
    updateStatus
};

async function getList(payloads) {
  
    try {
        const response = await instance.post(process.env.REACT_APP_BASE_URL+`superadmin/skill/get-list`, payloads);
        const result = await response;
        return result;
    } catch (error) {
        throw error;
    }
}

async function dropDown(payloads) {
  
    try {
        const response = await instance.post(process.env.REACT_APP_BASE_URL+`superadmin/skill/drop-down`, payloads);
        const result = await response;
        return result;
    } catch (error) {
        throw error;
    }
}

async function save(payloads) {
  
    try {
        const response = await instance.post(process.env.REACT_APP_BASE_URL+`superadmin/skill/save`, payloads);
        const result = await response;
        return result;
    } catch (error) {
        throw error;
    }
}

async function updateStatus(payloads) {
  
    try {
        const response = await instance.post(process.env.REACT_APP_BASE_URL+`superadmin/skill/update-status`, payloads);
        const result = await response;
        return result;
    } catch (error) {
        throw error;
    }
}



