import React, { useState, useEffect, useRef } from "react";
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/theme/neat.css';
import { javascript } from "@codemirror/lang-javascript";
import { UnControlled as CodeMirror } from 'react-codemirror2'
import { TestServices } from "../../services/Test";

const Answer = ({ no, questionDetails, submitQuestion }) => {
  
    const codeMirrorRef = useRef(null);

    const [answerValue, setAnswerValue] = useState("<h1>I ♥ react-codemirror2</h1>");

    const [isTrue, setIsTrue] = useState(false);

    const handleChange = (editor, data, value) => {
        setAnswerValue(value);
    };



    const handleRun = async () => {
      
        try {
            const response = TestServices.submit({
                "testCaseId":no+1,
                "sourceCode": answerValue.trim()
            });
            const result = await response;
           console.log(result.data);
          if (result.data.result === 1) {
                setIsTrue(true);
                submitQuestion(true);
            }
            else {
                setIsTrue(false);
                submitQuestion(false);
            }

            console.log(result);
        } catch (error) {

        }
    }


    /*useEffect(() => {
        const initialCode = `const example = "Initial code";`;
        if (codeMirrorRef.current) {
          codeMirrorRef.current.editor.setValue(initialCode);
        }
      }, []); */


    useEffect(() => {
        if (questionDetails.length > 0) {
            const initialCode = questionDetails[0].defultCode;
            if (codeMirrorRef.current) {
                codeMirrorRef.current.editor.setValue(initialCode);
            }
        }


    }, [questionDetails]);



    return (
        <>
            <div className="answer-panel">
                <div className="panel-header">
                    <div className="answer-actions">
                        <span className="text">Nodejs 16.0 <i className="bi bi-info-circle me-2"></i></span>
                        {/*<span className="answer-btn ms-4">Reset</span>
                        <span className="answer-btn">History</span>*/}
                    </div>
                    <span onClick={handleRun} className="answer-btn green-btn"><i onClick={handleRun} className="bi bi-play-circle me-1"></i> Run Code</span>
                </div>
                <div className="scrollable-panel">

                    <CodeMirror
                        ///value={answerValue}
                        options={{

                            theme: 'material',
                            lineNumbers: true
                        }}
                        ref={codeMirrorRef}
                        extensions={[javascript()]}

                        onChange={handleChange}
                    />




                    {/* <pre><code>async function firstSuccessfulPromise(promiseArray) {
                        // Write your code here
                    }

        let promise = firstSuccessfulPromise([new Promise((resolve, reject) => reject()), 
                    new Promise((resolve, reject) => resolve("Success!"))]);
        promise.then(result => console.log(result));

                        module.exports.firstSuccessfulPromise = firstSuccessfulPromise;
                    </code></pre> */}
                </div>
            </div>

            <div className="answer-output-panel">
                <div className="panel-header align-items-end">
                    <nav className="output-nav">
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <button className="nav-link" id="nav-output-tab" data-bs-toggle="tab" data-bs-target="#nav-output" type="button" role="tab" aria-controls="nav-output" aria-selected="true">Output</button>
                            <button className="nav-link active" id="nav-tests-tab" data-bs-toggle="tab" data-bs-target="#nav-tests" type="button" role="tab" aria-controls="nav-tests" aria-selected="false">Tests: 0 pass / 0 fail</button>

                        </div>
                    </nav>
                </div>
                <div className="scrollable-panel">
                    <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade" id="nav-output" role="tabpanel" aria-labelledby="nav-output-tab">

                        </div>
                        <div className="tab-pane fade show active" id="nav-tests" role="tabpanel" aria-labelledby="nav-tests-tab">
                            <ul className="tests-output">
                                <li>
                                    <div className="op-content">
                                        <i className="bi bi-x-circle-fill fail"></i>
                                        <span>{isTrue === true ? "success" : "fail"}</span>
                                    </div>
                                </li>
                                {/*<li>
                                    <div className="op-content">
                                        <i className="bi bi-x-circle-fill fail"></i>
                                        <span>The first successful Promise in promisesArray is not the first Promise in the array: Exception</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="op-content">
                                        <i className="bi bi-check-circle-fill pass"></i>
                                        <span> The function returns undefined only if no Promise is successful: Exception </span>
                                    </div>
                                </li>*/}
                            </ul>
                        </div>

                    </div>

                </div>
            </div>
        </>
    );


}

export default Answer;