import React, { useEffect, useState, useContext } from "react";
import { SkillServices } from "../../../../services/superadmin/Skill";
import { QuestionServices } from "../../../../services/superadmin/Question";
import { Link, useParams, useNavigate } from 'react-router-dom';
import Select from "react-select";
import Mcq from "./mcq/Index";
import TextEditor from "./TextEditor";
import { SQContext } from "../../../../context/superadmin/question";


const Index = () => {
  const navigate = useNavigate();
  const [skillList, setSkillList] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState({ value: "", label: "Select Skill" });
  const [difficultyOptions, setDifficultyOptions] = useState([
    { value: "", label: "Select Difficulty" },
    { value: "Easy", label: "Easy" },
    { value: "Hard", label: "Hard" },
    { value: "None", label: "None" }
  ]);
  const [selectedDifficulty, setSelectedDifficulty] = useState({ value: "", label: "Select Difficulty" });
  const { state, setState } = useContext(SQContext);
  let { id } = useParams();

  const getQuestionById = async () => {
    try {
      const response = await QuestionServices.getQuestionByid({ _id: id });
      modifyQuestion(response.data.data);
    } catch (error) {
      console.error("Error fetching question:", error);
    }
  };

  const modifyQuestion = (questionData) => {
    setState(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        name: questionData[0]?.name,
        type: questionData[0]?.type,
        skillID: questionData[0]?.skillID,
        point: questionData[0]?.point,
        difficulty: questionData[0]?.difficulty,
        description: questionData[0]?.description,
        expectedTime:questionData[0]?.expectedTime,
        mcq:questionData[0]?.questionmcqs,
        subType:questionData[0]?.subType,
      },
    }));
    if(questionData[0]?.skills.length){
      setSelectedSkill({value:questionData[0]?.skills[0]._id,label:questionData[0]?.skills[0].name});

    }
    setSelectedDifficulty({value:questionData[0]?.difficulty,label:questionData[0]?.difficulty});
  };

  const getSkills = async () => {
    try {
      const response = await SkillServices.dropDown();
      modifySkillList(response.data.data);
    } catch (error) {
      console.error("Error fetching skills:", error);
    }
  };

  const modifySkillList = (skillsData) => {
    const options = skillsData.map(item => ({ value: item._id, label: item.name }));
    setSkillList([{ label: "Select Skill", value: "" }, ...options]);
  };

  const handleSkillChange = (selectedOption) => {
    setSelectedSkill(selectedOption);
    setState(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        skillID: selectedOption.value,
      },
    }));
  };

  const handleDifficultyChange = (selectedOption) => {
    setSelectedDifficulty(selectedOption);
    setState(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        difficulty: selectedOption.value,
      },
    }));
  };

  const handleFormDataChange = (e, name) => {
    const newValue = e.target.value;
    if (state.formData[name] !== newValue) {
      setState(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          [name]: newValue,
        },
      }));
    }
  };

  const doUpdate = async () => {
    try {
      let payload={
        _id: id,
        name:state.formData.name,
        skillID:state.formData.skillID,
        point:state.formData.point,
        difficulty:state.formData.difficulty,
        description:state.formData.description,
        expectedTime:state.formData.expectedTime,
        mcq:state.formData.mcq,
        type:state.formData.type,
        subType:state.formData.subType,
      };
       await QuestionServices.update(payload);

      navigate('/superadmin/library'); 
      
    } catch (error) {

      setState(prevState => ({
        ...prevState,
        error: error.response.data.data.errors
      }));

    }
  };

  useEffect(() => {
    getQuestionById();
    getSkills();
  }, []);


  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-12">
            <div className="home-tab">
              <div className="d-sm-flex align-items-center justify-content-between border-bottom">
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <Link to="/superadmin/library" className="nav-link active ps-0" id="home-tab" data-bs-toggle="tab" href="#overview" role="tab" aria-controls="overview" aria-selected="true">
                      <i className="icon-arrow-left"></i> Back
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="tab-content tab-content-basic">
                <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview">
                  <div className="row">
                    <div className="col-3"><h5 className="new-title">Summary</h5></div>
                    <div className="col-9 grid-margin stretch-card">
                      <div className="card card-rounded">
                        <div className="card-body">
                         
                            <div className="form-group">
                              <label htmlFor="">Question Name</label>
                              <input type="text" className="form-control" onChange={(e) => handleFormDataChange(e, "name")} value={state.formData.name} placeholder="Enter question name" />
                              <div className="validation-error mt-2">{state.error?.name}</div>
                            </div>
                            <div className="row">
                              <div className="col-6">
                                <div className="form-group">
                                  <label htmlFor="skill-select">Skill</label>
                                  <Select
                                    id="skill-select"
                                    value={selectedSkill}
                                    onChange={handleSkillChange}
                                    options={skillList}
                                    className="small text-left"
                                  />
                                  <div className="validation-error mt-2">{state.error?.skillID}</div>
                                </div>
                                <div className="form-group">
                                  <label htmlFor="expected-time">Expected Time (Min)</label>
                                  <input type="number" id="expected-time" value={state.formData.expectedTime} onChange={(e) => handleFormDataChange(e, "expectedTime")} className="form-control" aria-label="Text input with " />
                                  <div className="validation-error mt-2">{state.error?.expectedTime}</div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group">
                                  <label htmlFor="difficulty-select">Difficulty</label>
                                  <Select
                                    id="difficulty-select"
                                    value={selectedDifficulty}
                                    onChange={handleDifficultyChange}
                                    options={difficultyOptions}
                                    className="small text-left"
                                  />
                                  <div className="validation-error mt-2">{state.error?.difficulty}</div>
                                </div>
                                <div className="form-group">
                                  <label htmlFor="points">Points</label>
                                  <input type="text" id="points" value={state.formData.point} onChange={(e) => handleFormDataChange(e, "point")} className="form-control" aria-label="Text input with " />
                                  <div className="validation-error mt-2">{state.error?.point}</div>
                                </div>
                              </div>
                            </div>
                         
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-3"><h5 className="new-title">Content</h5></div>
                    <div className="col-9 grid-margin stretch-card">
                      <div className="card card-rounded">
                        <div className="card-body">
                         
                            <div className="form-group">
                              <label htmlFor="">Description</label>
                              <TextEditor />
                              <div className="validation-error mt-2">{state.error?.description}</div>
                            </div>
                           
                            <Mcq />

                          

                        </div>
                      </div>


                    </div>

                    <div className="btn-wrapper d-flex justify-content-end">
                      <Link to="/superadmin/library" className="btn btn-otline-dark"><i className="icon-cross"></i> Cancel</Link>
                      <button onClick={doUpdate} className="btn btn-primary text-white me-0"><i className="icon-cloud"></i> Save Changes</button>
                    </div>

                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Index