import React, { useEffect, useState } from "react";
import Head from "../../layout/Header";
import { Link } from "react-router-dom";
import TaskHead from "../common/Header";
import TopSearch from "./Search";
import Filter from "./Filter";
import List from "./LIst";
import { useParams } from 'react-router-dom';

const Test = () => {
  const { id } = useParams();

  return (
    <main class="app-inner">
      <Head />

      <div class="main-content">
        <div class="page-header border-bottom">
          <div class="container">
            <TaskHead  id={id}/>
            <div class="row">
              <div class="col">
                <ul class="nav-secondary">
                  <li><Link to={`/client/test-questions/${id}`}>Questions</Link></li>
                  <li><Link to={`/client/test-library/${id}`} class="active">Library</Link></li>
                  <li><Link to={`/client/test-candidates/${id}`}>Candidates</Link></li>
                  <li><Link to={`/client/test-settings/${id}`}>Settings</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      
        <div className="container">
          <div class="py-4 row">
            <div class="col-lg-3 page-header-left">
              <h1>Library</h1>
            </div>
            <TopSearch />
          </div>
          <div class="row">
            <Filter />
            <List />
          </div>

        </div>

      </div>
    </main>
  );
}
export default Test