// userSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createTaskApi,
  getList,
  getStatusCount,
  updateUserApi,
  deleteUserApi,
} from "./testAPI";

export const createTask = createAsyncThunk(
  "task/createTask",
  async (taskData, { rejectWithValue }) => {
    try {
      const response = await createTaskApi(taskData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchTests= createAsyncThunk(
  "users/fetchTests",
  async (payloads, { rejectWithValue }) => {
    try {
      const response = await getList(payloads);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const fetchTestsStatusCount= createAsyncThunk(
  "users/fetchTestsStatusCount",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getStatusCount();
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (data, { rejectWithValue }) => {
    try {
      let userId = data.userId;
      let updatedUserData = data.updatedUserData;
      console.log("editUserIDswsws", data);
      const response = await updateUserApi(userId, updatedUserData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (userId, { rejectWithValue }) => {
    try {
      await deleteUserApi(userId);
      return userId;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const testSlice = createSlice({
  name: "tests",
  initialState: {
    tests: [],
    loading: true,
    firstLoading: true,
    error: null,
    validationErrors: {},
    editUserID: "",
    deleteUserId: "",
    isOpenAddModal:false,
    statusCount:[],
    isRedirect:false,
    isRedirectId:null
  },
  reducers: {
    validateUser: (state, action) => {
      state.validationErrors = action.payload;
    },
    getEditUser: (state, action) => {
      // Updated action name for consistency
      state.editUserID = action.payload;
    },
    removeUser: (state, action) => {
      state.deleteUserId = action.payload;
    },
    addModal: (state, action) => {
      state.isOpenAddModal = true;
    },
    closeAddModal: (state, action) => {
      state.isOpenAddModal = false;
    },
    setRedirectFalse: (state, action) => {
      state.isRedirect=false;
      state.isRedirectId=null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetch tests
      .addCase(fetchTests.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTests.fulfilled, (state, action) => {
        state.loading = false;
        state.tests = action.payload;
        state.firstLoading= false;
      })
      .addCase(fetchTests.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Handle fetch status count
      .addCase(fetchTestsStatusCount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTestsStatusCount.fulfilled, (state, action) => {
        state.loading = false;
        state.statusCount = action.payload;
      })
      .addCase(fetchTestsStatusCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Handle create user
      .addCase(createTask.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createTask.fulfilled, (state, action) => {
        state.loading = false;
        console.log("action.payload",action.payload);
        //state.users.push(action.payload);
        state.isOpenAddModal = false;
        state.isRedirectId = action.payload?.data?._id;
        state.isRedirect = true;
      })
      .addCase(createTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Handle update user
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users = state.users.map((user) =>
          user.id === action.payload.id ? action.payload : user
        );
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Handle delete user
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users = state.users.filter((user) => user.id !== action.payload);
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export const { validateUser, getEditUser, removeUser, addModal, closeAddModal, setRedirectFalse } = testSlice.actions;

export default testSlice.reducer;
