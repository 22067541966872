import instance from "../../wrapper/withAxios";

export const AuthServices = {
    login,
    signup
};

async function login(payloads) {
  
    try {
        const response = await instance.post(process.env.REACT_APP_BASE_URL+`client-login`, payloads);
        const result = await response;
        return result;
    } catch (error) {
        throw error;
    }
}

async function signup(payloads) {
  
    try {
        const response = await instance.post(process.env.REACT_APP_BASE_URL+`signup`, payloads);
        const result = await response;
        return result;
    } catch (error) {
        throw error;
    }
}
