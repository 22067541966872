import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import { closeAddModal, saveCandidate } from '../../../../features/client/test/candidateSlice';
import { useParams } from 'react-router-dom';

const Add = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const { isOpenAddModal, error } = useSelector((state) => state.clientTestCandidate);

  const [formData, setFormData] = useState({ test_id:id, email: "", deadline: "", copyPaste: false, cheatingProtection:false });

  const handleName = (e) => {
    setFormData(previousState => ({
      ...previousState,
      ["email"]: e.target.value
    }));
  }


  const handleDeadline = (e) => {
    setFormData(previousState => ({
      ...previousState,
      ["deadline"]: e.target.value
    }));
  }


  const handleCopyPaste = (e) => {
    setFormData(previousState => ({
      ...previousState,
      ["copyPaste"]: e.target.checked
    }));
  }

  const handleCheatingProtection = (e) => {
    setFormData(previousState => ({
      ...previousState,
      ["cheatingProtection"]: e.target.checked
    }));
  }

  const closeModalHandle = () => {
    dispatch(closeAddModal());
  }



  const handleSubmit = () => {
    dispatch(saveCandidate(formData));
  }



  return (


    <Modal
      className="modal-dialog rt modal-dialog-scrollable" isOpen={isOpenAddModal}
    >

      <ModalHeader class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Invite Candidates</h5>

      </ModalHeader>
      <ModalBody>

        <div class="mb-4">
          <label><strong>Share via Email</strong></label>
          <textarea value={formData.email} onChange={handleName} class="form-control" placeholder="You can enter multiple email address separated by commas"></textarea>
          <div>{error?.data?.errors?.email}</div>
        </div>
        <div class="mb-4">
          <label class="d-block"><strong>Share via link</strong></label>
          <a href="" class="mb-3 d-block small" target="_blank">https://app.testdome.com/</a>
          <a href="javascript:;" class="btn btn-secondary btn-icon">
            <span class="icon-btn"><i class="bi bi-link"></i> Copy Link</span>
          </a>
        </div>
        <div class="mb-4">
          <label class="d-block"><strong>Deadline</strong></label>
          <input
            type="date"
            class="form-control"
            onChange={handleDeadline}
            value={formData.deadline} />
          <div>{error?.data?.errors?.deadline}</div>
        </div>
        <div class="mb-2 form-check">
          <input 
          type="checkbox" 
          class="form-check-input" 
          checked={formData.copyPaste}
          onChange={handleCopyPaste}
          id="exampleCheck2" />
          <label class="form-check-label" for="exampleCheck2" >Copy and paste protection</label>
          <div>{error?.data?.errors?.copyPaste}</div>
        </div>
        <div class="mb-3 form-check">
          <input
            type="checkbox"
            class="form-check-input"
            checked={formData.cheatingProtection}
            onChange={handleCheatingProtection}
            id="exampleCheck1" />
          <label class="form-check-label" for="exampleCheck1">Cheating protection</label>
          <div>{error?.data?.errors?.cheatingProtection}</div>
        </div>

      </ModalBody>
      <ModalFooter>
        <button type="button" class="btn btn-secondary" onClick={closeModalHandle}>Close</button>
        <div onClick={handleSubmit} class="btn btn-primary">Continue</div>
      </ModalFooter>
    </Modal>

  );

}

export default Add