
// questionService.js
import instance from "../../../wrapper/withAxios";



// Read all test Candidate
export const getCandidateApi = async (payloads) => {
   
  const response = await instance.post(process.env.REACT_APP_BASE_URL+`client/test-candidate/get-list`, payloads);
  return response;
     
};


export const saveCandidateApi = async (payloads) => {
   
  const response = await instance.post(process.env.REACT_APP_BASE_URL+`client/test-candidate/save`, payloads);
  return response;
     
};