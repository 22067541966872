import React from "react";
import Test from "../../components/test/Index";


const Index = () =>{

    return (

        <>
            <Test />
        </>

    );

};
export default Index