import React ,{useState} from "react";
//import Logo from "../../assets/images/logo.svg";
import {AuthServices} from "../../services/superadmin/Auth";
import { useNavigate } from 'react-router-dom';

const Index = () => {
    const navigate = useNavigate();
    const [formFiled,setFormFiled] = useState({email:"",password:""});
    const [error,setError] = useState("");

    const handleChange =(name,e)=>{
        
        setFormFiled(previousState => ({
            ...previousState,
            [name]: e.target.value,
          }));
    }

    const  handleSubmit  = async ()=>{
        try {
            const response = AuthServices.login({email:formFiled.email,password:formFiled.password});
            const result = await response;
            localStorage.setItem("user", JSON.stringify(result.data));
            navigate("/superadmin/dashboard")
          } catch (error) {
            setError(error.response.data.message);
          }
    }


    

    return (

        <>
            <div class="container-scroller">
                <div class="container-fluid page-body-wrapper full-page-wrapper">
                    <div class="content-wrapper d-flex align-items-center auth px-0">
                        <div class="row w-100 mx-0">
                            <div class="col-lg-4 mx-auto">
                                <div class="auth-form-light text-left py-5 px-4 px-sm-5">
                                    <div class="brand-logo text-center">
                                        HTML POC
                                    </div>
                                    <h4 class="text-center">Administrator Login {process.env.REACT_BASE_URL}</h4>
                                    {
                                        error!=="" &&
                                        <div>{error}</div>
                                    }
                                    <div class="pt-3">
                                        <div class="form-group">
                                            <label class="from-label">Email Address</label>
                                            <input type="email" onChange={(e)=>handleChange("email",e)} value={formFiled.email}  class="form-control form-control-lg" id="exampleInputEmail1" placeholder="Enter your email address" />
                                        </div>
                                        <div class="form-group">
                                            <label class="from-label">Password</label>
                                            <input type="password" onChange={(e)=>handleChange("password",e)}  value={formFiled.password} class="form-control form-control-lg" id="exampleInputPassword1" placeholder="Password" />
                                        </div>
                                        <div class="mt-3 d-grid gap-2">
                                            <button class="btn btn-block btn-primary btn-lg fw-medium auth-form-btn" onClick={handleSubmit}>SIGN IN</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*  content-wrapper ends */}
                </div>
               {/* page-body-wrapper ends */}
            </div>
        </>

    );

};
export default Index