import axios from 'axios'

const axiosInstance = axios.create({
  baseURL:  process.env.BASE_URL,
  // other configurations
})


// Request Interceptor to add the Authorization header dynamically
axiosInstance.interceptors.request.use(
  (config) => {
    // Get the token from localStorage (or other storage) where it's saved
    const user  = localStorage.getItem('user');  // Assuming you saved the JWT in localStorage
   if(user){
    const token=JSON.parse(user).User.token;

    // If token exists, add it to the Authorization header
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
  }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.log('call the refresh token api here')
      // Handle 401 error, e.g., redirect to login or refresh token
    }
    return Promise.reject(error)
  },
)

export default axiosInstance