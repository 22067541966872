// src/app/store.js
import { configureStore } from '@reduxjs/toolkit';
import clientTestReducer from '../features/client/test/testSlice';
import clientTestLibraryReducer from '../features/client/test/librarySlice';
import clientTestQuestionReducer from '../features/client/test/questionSlice';
import clientTestCandidateReducer from '../features/client/test/candidateSlice';



const store = configureStore({
  reducer: {
    clientTest: clientTestReducer,  // Adding the usersSlice reducer to the store
    clientTestLibrary:clientTestLibraryReducer,
    clientTestQuestion:clientTestQuestionReducer,
    clientTestCandidate:clientTestCandidateReducer
  },
});

export default store;