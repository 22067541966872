import { Routes, Route } from "react-router-dom";
import Dashboard from "../components/client/dashboard/Index";
import Test from "../components/client/test/Index";
import TestLibrary from "../components/client/test/library/Index";
import TestQuestions from "../components/client/test/question/Index";
import Testcandidates from "../components/client/test/candidate/Index";

import ProtectedClientRoute from "../components/middleware/ProtectedClientRoute";

const userRoutes = (

   
        <Routes>
            <Route path="/client" >
                <Route index element={<ProtectedClientRoute><Test /></ProtectedClientRoute>} />
                <Route path="dashboard" element={<ProtectedClientRoute><Dashboard /></ProtectedClientRoute>} />
                <Route path="test-library/:id" element={<ProtectedClientRoute><TestLibrary /></ProtectedClientRoute>} />
                <Route path="test-questions/:id" element={<ProtectedClientRoute><TestQuestions /></ProtectedClientRoute>} />
                <Route path="test-candidates/:id" element={<ProtectedClientRoute><Testcandidates /></ProtectedClientRoute>} />
            </Route>
        </Routes>

    
)

export default userRoutes