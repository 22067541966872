import instance from "../wrapper/withAxios";

var url ="http://localhost:3006/";

export const TestServices = {
    submit
   
};

async function submit(payloads) {
  
    try {
        const response = await instance.post(url+`run-assistant`, payloads);
        const result = await response;
        return result;
    } catch (error) {
        throw error;
    }
}




