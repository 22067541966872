// userSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCandidateApi,
  saveCandidateApi,
} from "./candidateAPI";

export const getCandidate = createAsyncThunk(
  "task/getCandidate",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getCandidateApi(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



export const saveCandidate = createAsyncThunk(
  "task/saveCandidate",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await saveCandidateApi(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const candidateSlice = createSlice({
  name: "candidate",
  initialState: {
    candidates: [],
    loading: true,
    firstLoading: true,
    error: null,
    search:"",
    validationErrors: {},
    isOpenAddModal:false
    
  },
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    addModal: (state, action) => {
      state.isOpenAddModal = true;
    },
    closeAddModal: (state, action) => {
      state.isOpenAddModal = false;
    },
    
  },
  extraReducers: (builder) => {
    builder
      // Handle fetch Library
      .addCase(getCandidate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCandidate.fulfilled, (state, action) => {
        state.loading = false;
        state.candidates = action.payload;
        state.refresh = false;
        state.firstLoading= false;
      })
      .addCase(getCandidate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.refresh = false;
      })

      // Handle  remove
      .addCase(saveCandidate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveCandidate.fulfilled, (state, action) => {
        state.loading = false;
        state.refresh = true;
        state.isOpenAddModal = false;
        
      })
      .addCase(saveCandidate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })





  },
});
export const { setSearch, addModal, closeAddModal } = candidateSlice.actions;

export default candidateSlice.reducer;
