import instance from "../../wrapper/withAxios";


export const CategoryServices = {
    getList,
    save,
    dropDown
};

async function getList(payloads) {
  
    try {
        const response = await instance.post(process.env.REACT_APP_BASE_URL+`superadmin/category/get-list`, payloads);
        const result = await response;
        return result;
    } catch (error) {
        throw error;
    }
}

async function dropDown(payloads) {
  
    try {
        const response = await instance.post(process.env.REACT_APP_BASE_URL+`superadmin/category/drop-down`, payloads);
        const result = await response;
        return result;
    } catch (error) {
        throw error;
    }
}

async function save(payloads) {
  
    try {
        const response = await instance.post(process.env.REACT_APP_BASE_URL+`superadmin/category/save`, payloads);
        const result = await response;
        return result;
    } catch (error) {
        throw error;
    }
}

