import instance from "../../wrapper/withAxios";

export const AuthServices = {
    login
};

async function login(payloads) {
  
    try {
        const response = await instance.post(process.env.REACT_APP_BASE_URL+`login`, payloads);
        const result = await response;
        return result;
    } catch (error) {
        throw error;
    }
}

