import React, {useContext} from "react";
import Nav from "../../../../components/superadmin/layout/Nav"
import LibraryEdit   from "../../../../components/superadmin/library/edit/Index"
import { MyContext } from "../../../../context/contextapi";
import { useNavigate } from 'react-router-dom';

const Index = () => {
    const navigate = useNavigate();
	const { state  } = useContext(MyContext);
   
   
     if (state.user==="dfdfd" &&  state.user === null) {
        navigate("/superadmin");
      } 

    return (
        <div className="with-welcome-text">
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper">
                    <Nav />
                    <LibraryEdit />
                </div>
            </div>
        </div>
    );

};
export default Index