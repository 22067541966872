import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { QuestionServices } from "../../../services/superadmin/Question";
import AddModal from "./Add";

const Index = () => {

  const [list, setList] = useState([]);
  const [firstLoad, setFirstLoad] = useState(false);
  const [addNewModal, setAddNewModal] = useState(false);
  const [totalItemsCount, setTotalItemCount] = useState(0);

  const getList = async () => {

    try {
      const response = QuestionServices.getList();
      const result = await response;
      setList(result.data.data);
      setTotalItemCount(result.data.total_count);
      setFirstLoad(true);

    } catch (error) {

    }
  }

  const handleModalOpen = () => {
    setAddNewModal(true);
  }

  const handleModalClose = () => {
    setAddNewModal(false);
  }

  const handleRefresh = () => {
    getList();
  }

  const handleStatus = async (e, item) => {
    const status = item.status === "Deactive" ? "Active" : "Deactive";
    try {
      await QuestionServices.updateStatus({ _id: item._id, status: status });
      getList();
    } catch (error) {

    }

  }

  useEffect(() => {
    getList();
  }, [])

  return (


    <div className="main-panel">
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-12">
            <div class="home-tab">
              <div class="d-sm-flex align-items-center justify-content-between border-bottom">
                <ul class="nav nav-tabs">
                  <li class="nav-item">
                    <Link class="nav-link active ps-0" to="/superadmin/library">Questions</Link>
                  </li>
                  <li class="nav-item">
                    <Link class="nav-link " to="/superadmin/skills">Skills</Link>
                  </li>
                </ul>
                <div>
                  <div class="btn-wrapper">

                  </div>
                </div>
              </div>

              <div class="tab-content tab-content-basic">
                <div class="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview">

                  <div class="row">

                    <div class="col-12 grid-margin stretch-card">
                      <div class="card card-rounded">
                        <div class="card-body">
                          <div class="d-sm-flex justify-content-between align-items-start">
                            <div>
                              <h4 class="card-title card-title-dash">Questions</h4>
                              <p class="card-subtitle card-subtitle-dash">You have {totalItemsCount}+ new questions</p>
                            </div>
                            <div>
                              <a href="javascript:;" onClick={handleModalOpen} className="btn btn-primary btn-lg text-white mb-0 me-0" ><i class="mdi mdi-plus"></i> Add new question</a>
                            </div>
                          </div>
                          <div class="table-responsive  mt-1">
                            <table class="table select-table">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th style={{ width: "400px;" }}>Question</th>
                                  <th>Type</th>
                                  <th>Status</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  list && list.map((item, index) => (

                                    <tr key={index}>
                                      <td>1</td>
                                      <td>
                                        <span class="text-bold">{item.name} </span>
                                      </td>
                                      <td>
                                        {item.type}
                                      </td>
                                      <td class="text-end">
                                        <div class="form-check form-switch">
                                          <input class="form-check-input" onChange={(e) => handleStatus(e, item)} checked={item.status === "Active" ? true : false} type="checkbox" id="flexSwitchCheckDefault" />
                                          <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                                        </div>
                                      </td>
                                      <td >
                                        <Link className="nav-link active" to={"/superadmin/library/"+item._id} >Edit</Link>
                                      </td>
                                    </tr>

                                  ))

                                }
                                {
                                  list.length === 0 && firstLoad == true &&

                                  <tr>
                                    <td colSpan={4} className="text-center">No Record Found</td>
                                  </tr>
                                }



                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>


                    </div>

                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
      <AddModal addNewModal={addNewModal} handleModalClose={handleModalClose} handleRefresh={handleRefresh} />
    </div>


  );

};
export default Index