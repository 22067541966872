// src/TextEditor.js
import React, { useState,useContext } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { SQContext } from "../../../../context/superadmin/question";


const TextEditor = () => {
  const { state, setState } = useContext(SQContext);
 

  

  const handleEditorChange = (html) => {
    setState(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        description: html,
       
      },
    }));
  };


  return (
    <div>
      
      <ReactQuill
        value={state.formData.description}
        onChange={handleEditorChange}
        modules={TextEditor.modules}
        formats={TextEditor.formats}
        placeholder="Write something..."
      />
      
    </div>
  );
};

TextEditor.modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline'],
    ['image'],
    ['clean'],
  ],
};

TextEditor.formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'image',
];

export default TextEditor;