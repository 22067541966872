import React,{ useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getQuestionCount } from '../../../../features/client/test/questionSlice';
import { addModal } from "../../../../features/client/test/candidateSlice";
import CandidateAddModal from "../../../../components/client/test/candidate/Add";
import { useParams } from 'react-router-dom';

const Header =()=>{
    const { id } = useParams();
    const dispatch = useDispatch();

    const libraryRefresh  = useSelector((state) => state.clientTestLibrary.refresh);
    const {refresh, questionsDetails } = useSelector((state) => state.clientTestQuestion);

    const handleQuestionDetails =()=>{
       dispatch(getQuestionCount({ page: 1, test_id: id, limit: 10, search: "" }));
    }

    const handleOpenCandidate =()=>{
        dispatch(addModal());
    }
    
    
  useEffect(() => {
       
        handleQuestionDetails();
        
    }, [dispatch,refresh, libraryRefresh]);

    return (

        <div class="row align-items-center">
            <div class="col-4 position-relative">
            <Link to="/client" class="btn-back"><i class="bi bi-arrow-left-short"></i></Link>
            <h1>MEAN Stack Developer <div class="badge text-bg-success ms-3"> Active</div></h1> 
            </div>
            <div class="col-5">
            <ul class="ul-stats">
                <li><span>Time</span> <strong>1:15 Hours</strong></li>
                <li><span>Questions</span> <strong>{questionsDetails?.data?.data.length}</strong></li>
                <li><span>Skills</span> <strong>4</strong></li>
            </ul>
            </div>
            <div class="col-3 text-end">                 
            <div class="btn-circle me-2" data-bs-toggle="modal" data-bs-target="#newTest"><i class="bi bi-copy" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Duplicate"></i></div>
                <div  target="_blank" class="btn-circle"><i class="bi bi-eye" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Preview"></i></div>
            <div onClick={handleOpenCandidate} class="btn btn-secondary btn-icon ms-2" data-bs-toggle="modal" data-bs-target="#inviteCandidates">
                <span class="icon-btn"><i class="bi bi-send"></i> Invite</span>
                </div>
            </div>
            <CandidateAddModal />
      </div> 
     
    );
}

export default Header;