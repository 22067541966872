import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../assets/images/logo.svg"
import { useNavigate } from 'react-router-dom';

const Nav = () => {
    const navigate = useNavigate();

    const logout =()=>{
        localStorage.removeItem("user");
        navigate("/superadmin")
    }

    return (

        <>
            <nav class="sidebar sidebar-offcanvas d-flex flex-column" id="sidebar">

                <div class="my-brand">
                    <Link class="navbar-brand brand-logo" to="#">
                        <img src={Logo} alt="logo" />
                    </Link>
                </div>
                <ul class="nav">
                    <li class="nav-item active">
                        <Link class="nav-link" to="/superadmin/dashboard">
                            <i class="mdi mdi-grid-large menu-icon"></i>
                            <span class="menu-title">Dashboard</span>
                        </Link>
                    </li>
                    <li class="nav-item ">
                        <Link class="nav-link" to="/superadmin/library">
                            <i class="mdi mdi-card-text-outline menu-icon"></i>
                            <span class="menu-title">Library</span>
                        </Link>
                    </li>
                    <li class="nav-item">
                        <Link class="nav-link" to="/superadmin/customers">
                            <i class="mdi mdi-layers-outline menu-icon"></i>
                            <span class="menu-title">Customers</span>
                        </Link>
                    </li>
                    <li class="nav-item">
                        <Link class="nav-link" to="/superadmin/users">
                            <i class="mdi mdi-account-circle-outline menu-icon"></i>
                            <span class="menu-title">Users</span>
                        </Link>
                    </li>
                    <li class="nav-item" onClick={logout}>
                        <div class="nav-link">
                            <i class="mdi mdi-power menu-icon"></i>
                            <span class="menu-title">Sign Out</span></div>
                        
                    </li>

                </ul>

            </nav>
        </>

    );

};
export default Nav