import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { MyProvider } from './context/contextapi';
import { SQuestionContextApi } from './context/superadmin/question';
import { combineProviders } from 'react-combine-provider';
import Public from "./routes/Public";
import Client from "./routes/Client";
import Test from "./routes/Test";
import Superadmin from "./routes/Superadmin";
import store from './app/store';
import { Provider } from 'react-redux';

const MasterProvider=combineProviders([MyProvider,SQuestionContextApi]);

export default function App() {
  return (
    <Provider store={store}>
        <MasterProvider>
          <BrowserRouter>
            {Public}
            {Client}
            {Test}
            {Superadmin}
          </BrowserRouter>
        </MasterProvider>
    </Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

