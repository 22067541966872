import React, { useContext } from "react";
import { SQContext } from "../../../../../context/superadmin/question";

const Mcq = () => {

    const { state, setState } = useContext(SQContext);

    const handleAddMore = () => {
        const newMcq = [...state.formData.mcq, { title: "", answer: false }];
        setState(prevState => ({
            ...prevState,
            formData: {
                ...prevState.formData,
                mcq: newMcq,
            },
        }));
    };

    const handleRemove = (index) => {
        const newMcq = state.formData.mcq.filter((_, i) => i !== index);
        setState(prevState => ({
            ...prevState,
            formData: {
                ...prevState.formData,
                mcq: newMcq,
            },
        }));
    };

    const handleAnswerChange = (e,index) => {
        const newMcq = state.formData.mcq;

        const updatedMcq = newMcq.map((item, i) => {
            return { ...item, answer: false }; 
        });

        updatedMcq[index].answer=e.target.checked;
       
        setState(prevState => ({
            ...prevState,
            formData: {
                ...prevState.formData,
                mcq: updatedMcq,
            },
        }));
    };

    const handleTitleChange = (e,index) => {

        
        const newMcq = state.formData.mcq;
        newMcq[index].title=e.target.value;
       
        setState(prevState => ({
            ...prevState,
            formData: {
                ...prevState.formData,
                mcq: newMcq,
            },
        }));
    };

    

    return (
        <div>

            <div className="form-group">
                <label for="exampleInputConfirmPassword1">Options </label>

                
                <div className="validation-error mt-2">{state.error?.singleMcq}</div>
                {state.formData.mcq.length > 0 && state.formData.mcq.map((item, index) => (

                    <React.Fragment>
                    <div className={state.formData.mcq.length > 1 ? "input-group mt-2" : "input-group"} key={index}>
                        <div className="input-group-text">
                            <input checked={item.answer} onChange={(e)=>handleAnswerChange(e,index)} className="form-check-input mt-0" type="radio" aria-label="Radio button for following text input" />
                        </div>
                        <input type="text" value={item.title}  onChange={(e)=>handleTitleChange(e,index)} className="form-control" aria-label="Text input with radio button" />
                        {state.formData.mcq.length > 1 &&
                            <div onClick={()=>handleRemove(index)} className="input-group-text">
                                <i  className="icon-trash"></i>
                            </div>
                        }
                    </div>
                    <div className="validation-error mt-2">{state.error?.["mcq."+index+".title"]?"Text field is required.":""}</div>
                    </React.Fragment>
                ))}


                {state.formData.mcq.length === 0 &&

                    <div className="input-group">
                        <div className="input-group-text">
                            <input className="form-check-input mt-0" type="radio" value="" aria-label="Radio button for following text input" />
                        </div>
                        <input type="text" className="form-control" aria-label="Text input with radio button" />

                    </div>

                }


                <div className="d-flex mt-2">
                    <button onClick={handleAddMore} className="btn btn-outline-dark mb-3">Add More</button>
                </div>
            </div>

        </div>
    );
}

export default Mcq;