import React, {createContext, useState} from 'react';

const MyContext = createContext();

const MyProvider = ({children}) => {
  const [state, setState] = useState({
    searchText: '',
    formData:{},
    user:JSON.parse(localStorage.getItem("user"))
  });

  return (
    <MyContext.Provider value={{state, setState}}>
      {children}
    </MyContext.Provider>
  );
};

export {MyContext, MyProvider};
