import React, {useState, useEffect} from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import {closeAddModal, createTask } from '../../../features/client/test/testSlice';
import Select from "react-select";
import { SkillServices } from "../../../services/superadmin/Skill";

const Add = () => {
  const dispatch = useDispatch();
  const { isOpenAddModal,error } = useSelector((state) => state.clientTest);

  const [formData, setFormData] = useState({name:"",skillID:[]});
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [skillList, setSkillList] = useState([]);

  const handleSkill =(selected)=>{
    setSelectedSkill(selected);
    const skillID=selected.map(skill => skill.value);
    setFormData(previousState =>({
      ...previousState,["skillID"]:skillID
    }))
  }

  const handleName = (e) => {
    setFormData(previousState => ({
      ...previousState,
      ["name"]: e.target.value
    }));
  }

  const closeModalHandle =()=>{
    dispatch(closeAddModal());
  }

  const getSkillDropDown = async () => {
    const response = await SkillServices.dropDown();
    modifySkillList(response.data.data);
 }


const modifySkillList = (data) => {
    var tempData = [];
    data.map((item) => {
        var option = { value: item._id, label: item.name };
        tempData.push(option);
    });
    setSkillList(tempData);
};

const handleSubmit =()=>{
  dispatch(createTask(formData));
}

useEffect(()=>{
  getSkillDropDown();
},[]);

    return (


        <Modal
            className="modal-dialog rt modal-dialog-scrollable" isOpen={isOpenAddModal}
        >
            
            <ModalHeader class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Create new test</h5>

            </ModalHeader>
            <ModalBody>
                 <div class="mt-3">
                  <div class="mb-3">
                    <label for="" class="form-label">Test Name</label>
                    <input type="text" value={formData.name} class="form-control"  onChange={handleName} placeholder="Enter test name" />                
                    <div>{error?.data?.errors?.name}</div>
                 </div>
                  <div class="mb-3">
                    <label for="" class="form-label">Skills</label>
                    <Select
                        value={selectedSkill}
                        onChange={handleSkill}
                        options={skillList}
                        className="small text-left"
                        isMulti
                        />
                        <div>{error?.data?.errors?.skillID}</div>
                   
                  </div>
                </div>
            </ModalBody>
            <ModalFooter>
            <button type="button" class="btn btn-secondary" onClick={closeModalHandle}>Close</button>
            <div onClick={handleSubmit} class="btn btn-primary">Continue</div>
            </ModalFooter>
        </Modal>

    );

}

export default Add