import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setSearch } from '../../../../features/client/test/librarySlice';

const Search =()=>{
    const dispatch = useDispatch();
    const { search,  loading, error } = useSelector((state) => state.clientTestLibrary);

    const handleSearch = (e)=>{
        dispatch(setSearch(e.target.value));
    }
    

    return (

        <div class=" col-lg-9 d-flex justify-content-between page-header-right">
        <div class="fr-search me-3">
          <input type="text" onChange={handleSearch} value={search} class="search-input" name="" placeholder="Search..." />
        </div>


        {/*<div class="dropdown me-1">
          <a href="" class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            Sort by: Newest
          </a>
          <ul class="dropdown-menu" >
            <li><a class="dropdown-item" href="#">New</a></li>
            <li><a class="dropdown-item" href="#">Test 2</a></li>
            <li><a class="dropdown-item" href="#">Test 3</a></li>
            <li><a class="dropdown-item" href="#">Test 4</a></li>
            <li><a class="dropdown-item" href="#">Test 5</a></li>
          </ul>
        </div>*/}

      </div>

    );
}

export default Search;