import React, {createContext, useState} from 'react';

const SQContext = createContext();

const SQuestionContextApi = ({children}) => {
  const [state, setState] = useState({
    formData:{
        name:"hi",
        type:"",
        skillID:"",
        point:"",
        difficulty:"",
        description:"",
        expectedTime:"",
        subType:"",
        mcq:[]
    },
    error:""
  });


  return (
    <SQContext.Provider value={{state, setState}}>
      {children}
    </SQContext.Provider>
  );
};

export {SQContext, SQuestionContextApi};
