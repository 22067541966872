import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {


  const getUserFromLocalStorage = () => {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null; // Parse and return user data or null
  };


const user = getUserFromLocalStorage();
    if (!user ||  user.User.role !== 'Admin') {
    return <Navigate to="/superadmin" replace />;
  }


  return children;
};



export default ProtectedRoute;