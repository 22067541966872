import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilterDifficulty, setFilterType } from '../../../../features/client/test/librarySlice';

const Filter =()=>{

  const dispatch = useDispatch();
 
  const { filterDifficulty, filterType } = useSelector((state) => state.clientTestLibrary);
  const handleCheckboxChange = (event) => {

    const value = event.target.value;

    let newFilterDifficulty=[];
   
      if (filterDifficulty.includes(value)) {
        // If already selected, remove it
        newFilterDifficulty = filterDifficulty.filter((item) => item !== value);
      } else {
        // If not selected, add it to the list
        newFilterDifficulty = [...filterDifficulty, value];
      }

      dispatch(setFilterDifficulty(newFilterDifficulty));
   
  };

  const handleTypeCheckboxChange = (event) => {

    const value = event.target.value;

    let newFilterType=[];
   
      if (filterType.includes(value)) {
        // If already selected, remove it
        newFilterType = filterType.filter((item) => item !== value);
      } else {
        // If not selected, add it to the list
        newFilterType = [...filterType, value];
      }

      dispatch(setFilterType(newFilterType));
   
  };

  

  

    return (

        <div class="col-lg-3">
                  <div class="ts-card card-sm">
                    <span class="small-heading mb-4">Fiter By</span>
                    <div class="mb-4">
                      <label>Skills</label>
                      <input type="text" name="" class="form-control" placeholder="Filter by skills" /> 
                    </div>
                    <div class="mb-4">
                      <label>Difficulty</label>
                      <div class="form-check">
                        <input 
                          value="Hard"  
                          class="form-check-input" 
                          type="checkbox"  
                          checked={filterDifficulty.includes("Hard")} 
                          onChange={handleCheckboxChange}
                          id="flexCheckDefault" />
                        <label class="form-check-label" for="flexCheckDefault">
                          Hard
                        </label>
                      </div>
                       <div class="form-check">
                        <input 
                          value="Easy" 
                          class="form-check-input" 
                          type="checkbox"  
                          checked={filterDifficulty.includes("Easy")} 
                          onChange={handleCheckboxChange}
                          id="flexCheckDefault" />
                        <label class="form-check-label" for="flexCheckDefault">
                          Easy
                        </label>
                      </div>
                    </div>

                    <div class="mb-4">
                      <label>Question Type</label>
                      <div class="form-check">
                        <input 
                          class="form-check-input" 
                          type="checkbox" 
                          value="Code"
                          checked={filterType.includes("Code")} 
                          onChange={handleTypeCheckboxChange} 
                          id="flexCheckDefault" />
                        <label class="form-check-label" for="flexCheckDefault">
                          Code
                        </label>
                      </div>
                       <div class="form-check">Code
                        <input 
                        class="form-check-input" 
                        type="checkbox"
                        value="Multiple Choice Question"
                        checked={filterType.includes("Multiple Choice Question")} 
                        onChange={handleTypeCheckboxChange} 
                        id="flexCheckDefault" />
                        <label class="form-check-label" for="flexCheckDefault">
                          MCQs
                        </label>
                      </div>
                      <div class="form-check">
                      <input 
                        class="form-check-input" 
                        type="checkbox"
                        value="Text Answer"
                        checked={filterType.includes("Text Answer")} 
                        onChange={handleTypeCheckboxChange} 
                        id="flexCheckDefault" />
                        <label class="form-check-label" for="flexCheckDefault">
                          Text Answer
                        </label>
                      </div>
                      <div class="form-check">
                      <input 
                        class="form-check-input" 
                        type="checkbox"
                        value="Fill in the blanks"
                        checked={filterType.includes("Fill in the blanks")} 
                        onChange={handleTypeCheckboxChange} 
                        id="flexCheckDefault" />
                        <label class="form-check-label" for="flexCheckDefault">
                          Fill in the blanks
                        </label>
                      </div>
                    </div>

                  </div>
                </div>
    );
}

export default Filter;