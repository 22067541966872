import React,{ useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getLibrary, addQuestion, removeQuestion } from '../../../../features/client/test/librarySlice';
import { useParams } from 'react-router-dom';
import Pagination from "react-js-pagination";

const List =()=>{
    const { id } = useParams();
    const dispatch = useDispatch();
    const { search, library, filterDifficulty, filterType,  loading, error,refresh } = useSelector((state) => state.clientTestLibrary);
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);


    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };
  
     const getList = () => {
        dispatch(getLibrary({ page: currentPage, limit: itemsPerPage, filterDifficulty:filterDifficulty, filterType:filterType, search: search, test_id:id }));
      };

     const handleRemove =(questionId)=>{
      dispatch(removeQuestion({ id:questionId, test_id:id }));
     }

     const handleAdd =(questionId)=>{
      
      dispatch(addQuestion({ id:questionId, test_id:id }));
     }
    
    
      useEffect(() => {
          getList();
      }, [dispatch, search,filterDifficulty,filterType,currentPage]);
    
    
      useEffect(() => {
        if(refresh){
          getList();
        }
      }, [dispatch,refresh]);
    


    return (

        <div class="col-lg-9">
                <div class="grid-wrapper">                
                    <table class="table table-custom">
                      <thead>
                        <tr>
                          <th colspan="2">
                            <div class="d-flex theader">
                            {library?.data?.total_count} Questions
                            {/*<div class="ms-3 filters">
                                <a href="" class="btn-filter">Nodejs <i class="bi bi-x-lg "></i></a>
                              </div> */}
                            </div>
                          </th>
                          
                        </tr>
                      </thead>
                      <tbody>
                      {library?.data?.data.map((item, index) =>

                            <tr>
                            <td>
                            <div class="lib-que">
                                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#viewQuestion"> 
                                <h4> {item?.name} </h4>
                                <p>Agenda - You have developed a plan for what to cover in your next team meeting, as shown in developed a plan for what to cover in your next team meeting, as shown in</p>
                                </a>
                            </div>                            
                            <ul class="lib-info">
                                <li>
                                <label>Skill</label>
                                <strong><i class="bi bi-app-indicator"></i> Nodejs</strong>
                                </li>
                                <li>
                                <label>Time</label>
                                <strong><i class="bi bi-clock"></i>  {item?.expectedTime}  Mins</strong>
                                </li>
                                <li>
                                <label>Type</label>
                                <strong><i class="bi bi-patch-question"></i>  {item?.type}</strong>
                                </li>
                                <li>
                                <label>Difficulty</label>
                                <strong><i class="bi bi-speedometer2"></i> {item?.difficulty}</strong>
                                </li>
                            </ul>
                            </td>
                            <td class="text-end">

                              
                            {Array.isArray(item?.testQuestion) &&  item?.testQuestion.length >0 ?
                             <div onClick={()=>handleRemove(item._id)}  class="btn btn-action negative"><span class="icon-btn"><i class="bi bi-x-circle-fill"></i> Remove</span></div> 
                            :
                              <div onClick={()=>handleAdd(item._id)}  class="btn btn-action "><span class="icon-btn"><i class="bi bi-plus-circle-fill"></i> Add</span></div>  
                              }
                            </td>
                            </tr>

                      )}

                     {loading === false && (!Array.isArray(library?.data?.data) || library?.data?.data.length < 1) &&
                        <tr><td colSpan="1">No Question!</td></tr>
                      }

                        
                       </tbody>
                       </table>

                       {Array.isArray(library?.data?.data) && library?.data?.data.length > 0 &&
                        <div class="table-footer">
                          <h6>{itemsPerPage} of {library?.data?.total_count} records</h6>
                          <Pagination
                            activePage={currentPage}
                            itemsCountPerPage={itemsPerPage}
                            totalItemsCount={library?.data?.total_count}
                            pageRangeDisplayed={10}
                            onChange={handlePageChange}
                            prevPageText={'Previous'}
                            nextPageText={'Next'}
                            itemClass="page-item"
                            linkClass="page-link"
                          />

                        </div>
                      }

                       </div>
        </div>
    );
}

export default List;