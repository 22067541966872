import { Routes, Route } from "react-router-dom";
import Login from "../components/client/auth/Login";
import Signup from "../components/client/auth/Signup";
const userRoutes = (

   
        <Routes>
            <Route path="/" >
                <Route index element={<Signup />} />
                <Route  path="login"  element={<Login />} />
            </Route>
        </Routes>

    
)

export default userRoutes