import { Routes, Route } from "react-router-dom";
import Login from "../pages/superadmin/login"
import Dashborad from "../pages/superadmin/dashboard/index"
import Library from "../pages/superadmin/library/index"
import LibraryEdit from "../pages/superadmin/library/edit/index"
import Users from "../pages/superadmin/users/index"
import Customers from "../pages/superadmin/customers/index"
import Skills from "../pages/superadmin/skills/index"
import ProtectedRoute from "../components/middleware/ProtectedAdminRoute";
const userRoutes = (
  
    <Routes>
          <Route path="/superadmin" >
            <Route index element={<Login />} />
            <Route  path="dashboard"  element={<ProtectedRoute><Dashborad /></ProtectedRoute>} />
            <Route  path="library"  element={<ProtectedRoute><Library /></ProtectedRoute>} />
            <Route  path="library/:id"  element={<ProtectedRoute><LibraryEdit /></ProtectedRoute>} />
            <Route  path="users"  element={<ProtectedRoute><Users /></ProtectedRoute>} />
            <Route  path="customers"  element={<ProtectedRoute><Customers /></ProtectedRoute>} />
            <Route  path="skills"  element={<ProtectedRoute><Skills /></ProtectedRoute>} />
          </Route>
          
        </Routes>
  )
   
  export default userRoutes