import React from "react";
import Nav from "../../../components/superadmin/layout/Nav"
import Skills from "../../../components/superadmin/skills/Index"

const Index = () => {

    return (
        <div className="with-welcome-text">
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper">
                    <Nav />
                    <Skills />
                </div>
            </div>
        </div>
    );

};
export default Index