import React,{useEffect} from "react";

const Question = ({no,questionDetails})=>{


  useEffect(()=>{

    

  },[questionDetails]);


    return (
        <div className="question-panel">
          {questionDetails.length>0 &&
            <React.Fragment>
                    <div className="panel-header">
                      Q. {no+1}/10 <span className="skill-bubbles"><a href="javascript:;">{questionDetails[0]?.language}</a></span>
                    </div>
                    <div className="scrollable-panel">
                        
                        {questionDetails[0]?.description}
                    </div>
                    </React.Fragment>
        }
       </div>
    );


}

export default Question;