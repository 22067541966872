import React, {useEffect, useState} from 'react';
//import '../../assets/css/test/custom.css';
//import Logo from "../../assets/images/logo-ts.svg";
import Question from "./Question";
import Answer from "./Answer";
import testCase from "./cases.json";

const Test = () => {

  const [currentNo,setCurrentNo]=useState(0);
  const [questionDetails,setQuestionDetails]=useState([]);
  const [allQuestions,setAllQuestions]=useState(testCase);


  const getQuestion =()=>{
     let qs=[testCase.testCases[currentNo]];
    setQuestionDetails(qs);
    
  }

  const submitQuestion =(flag)=>{
   
   let newQuestions=testCase;
   newQuestions.testCases[currentNo].answer=flag;
   setAllQuestions(newQuestions);
   
 }

  const nextQuestion=()=>{

    if(currentNo===9)
      {
        console.log(allQuestions);
        alert("finished");
      }
      else
      {
        setCurrentNo(currentNo+1);
      }

  
   
 }


    
  useEffect(()=>{

    getQuestion();

  },[currentNo]);

    return (
        <main className="test-panel">      
        <div className="container-fluid"> 
    
          <nav className="navbar navbar-expand-lg navbar-light border-bottom">         
            <a className="navbar-brand" href="test">
            HTML POC
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="test">MERN Stack Developer Test</a>
                </li>              
              </ul>
              <div className="d-flex icon-style-1 pe-3">
                <strong><i className="bi bi-hourglass me-2"></i></strong>
                <span><strong>10 min remaining </strong></span>
              </div>
            </div>        
          </nav>
  
          <section className="play-test">
            {questionDetails!=="" &&
              <React.Fragment>
                <Question no={currentNo} questionDetails={questionDetails}/>
                <Answer no={currentNo} questionDetails={questionDetails} submitQuestion={submitQuestion}/>
              </React.Fragment>
            }
    
          </section>
    
           <aside className="counts">
            <div className="counts-actions">
               <ul>
                 <li><a className={currentNo==0?"active":""} href="javascript:;">1</a></li>
                 <li><a  className={currentNo==1?"active":""} href="javascript:;">2</a></li>
                 <li><a  className={currentNo==2?"active":""} href="javascript:;">3</a></li>
                 <li><a className={currentNo==3?"active":""}  href="javascript:;">4</a></li>
                 <li><a className={currentNo==4?"active":""}  href="javascript:;">5</a></li>
                 <li><a  className={currentNo==5?"active":""} href="javascript:;">6</a></li>
                 <li><a  className={currentNo==6?"active":""} href="javascript:;">7</a></li>
                 <li><a  className={currentNo==7?"active":""} href="javascript:;">8</a></li>
                 <li><a  className={currentNo==8?"active":""} href="javascript:;">9</a></li>
                 <li><a  className={currentNo==9?"active":""} href="javascript:;">10</a></li>
               </ul>
               <div className="counts-btns">
                 <a href="javascript:;" onClick={nextQuestion} className="btn btn-secondary me-2">Submit and take a break</a>
                 <a href="javascript:;" onClick={nextQuestion} className="btn btn-primary">Submit and Next</a>
               </div>
             </div>
           </aside>
    
       
    
    
       </div>     
     </main>
    
    );
};

export default Test;