import React, { useState, useEffect } from "react";
import { QuestionServices } from "../../../services/superadmin/Question";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Select from "react-select";

const Add = ({ addNewModal, handleModalClose, handleRefresh }) => {

    const [formFiled, setFormFiled] = useState({ name: "", type: "" });
    const [error, setError] = useState("");
    const [categoryList, setCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({ value: "", label: "Select question type" });


    const getDropDown = async () => {

        const response = await QuestionServices.getType();
        modifyCategoryList(response.data.data);
    }


    const modifyCategoryList = (data) => {
        var tempData = [{ label: "Select question type", value: "" }];
        data.map((item) => {
            var option = { value: item._id, label: item.name };
            tempData.push(option);
        });
        setCategoryList(tempData);
    };

    const handleCategory = (e) => {
        var selected = { value: e.value, label: e.label };
        setSelectedCategory(selected);

        setFormFiled(previousState => ({
            ...previousState,
            ['type']: e.value,
        }));
    };


    const handleChange = (name, e) => {

        setFormFiled(previousState => ({
            ...previousState,
            [name]: e.target.value,
        }));
    }

    const handleClear = () => {
        setError("");
        setFormFiled({ name: "", categoryID: "" });
        setSelectedCategory({ value: "", label: "Select question type" });
    }

    const handleClose = () => {
        handleClear();
        handleModalClose();
    }

    const handleSubmit = async () => {
        console.log(formFiled);
        try {
            await QuestionServices.save({ name: formFiled.name, type: formFiled.type });
            handleClose();
            handleRefresh();
        } catch (error) {
            console.log(error.response.data.data.errors);
            setError(error.response.data.data.errors);
        }
    }

    useEffect(() => {
        getDropDown();
    }, []);



    return (



        <Modal
            size="md"
            backdrop="static"
            isOpen={addNewModal}
            toggle={handleClose}
        >
            <ModalHeader className="" toggle={handleClose}>
                Add New Question
            </ModalHeader>
            <ModalBody>

                <div className="forms-sample">

                <div className="form-group">

                    <label> Question Name </label>
                    <input type="text" required onChange={(e) => handleChange("name", e)} value={formFiled.name} class="form-control " placeholder="Enter name" />
                    <div className="validation-error mt-2">{error?.name}</div>
                </div>
                <div className="form-group">
                    <label> Question Type</label>

                    <Select

                        value={selectedCategory}
                        onChange={
                            handleCategory
                        }
                        options={categoryList}
                        className="small text-left"
                    />
                    <div className="validation-error mt-2">{error?.type}</div>

                </div>

                <button
                        onClick={handleClose}
                        className="btn btn-light me-2"
                        data-dismiss="modal"
                    >
                        Cancel
                    </button>

                    <button
                        className="btn btn-primary"
                        onClick={handleSubmit}
                    >
                        Submit
                    </button>
                    </div>
            </ModalBody>
            <ModalFooter>
               
                  
               
            </ModalFooter>
        </Modal>


    );

};
export default Add