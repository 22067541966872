import React, { useContext, useEffect } from "react";
import Nav from "../../../components/superadmin/layout/Nav";
import Library from "../../../components/superadmin/library/Index";

const Index = () => {


    return (
        <div className="with-welcome-text">
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper">
                    <Nav />
                    <Library />
                </div>
            </div>
        </div>
    );
};

export default Index;