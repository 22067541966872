import { Routes, Route } from "react-router-dom";
import Test from "../pages/test/Index"


const userRoutes = (
  
    <Routes>
          <Route path="/test" >
            <Route index element={<Test />} />
          </Route>
          
        </Routes>
  )
   
  export default userRoutes