import React from "react";
import { Link } from "react-router-dom";
//import Logo from "../../../assets/images/brand-logo.svg";
const Header = () => {
 

    return (

        <nav class="navbar navbar-expand-lg fixed-top py-2 border-bottom">
            <div class="container">
                <Link to="" class="me-5">  HTML POC </Link>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <Link class="nav-link active" aria-current="page" to="/">Tests</Link>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link" to="/">Candidates</Link>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link" to="/">Library</Link>
                        </li>
                    </ul>
                    <ul class="nav nav-pills">
                        <li class="dropdown">
                            <Link class="avtar" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <span>J</span>
                            </Link>
                            <ul class="dropdown-menu">
                                <li><Link class="dropdown-item" to="#">Account</Link></li>
                                <li><Link class="dropdown-item" to="#">Logout</Link></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

    );
}

export default Header 