// userSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getLibraryApi,
  addQuestionApi,
  removeQuestionApi
} from "./libraryAPI";

export const getLibrary = createAsyncThunk(
  "task/getLibrary",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getLibraryApi(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addQuestion = createAsyncThunk(
  "task/addQuestion",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await addQuestionApi(payload);
      
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeQuestion = createAsyncThunk(
  "task/removeQuestion",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await removeQuestionApi(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const librarySlice = createSlice({
  name: "library",
  initialState: {
    library: [],
    loading: true,
    firstLoading: true,
    error: null,
    search:"",
    filterDifficulty:[],
    filterType:[],
    validationErrors: {},
    
  },
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setFilterDifficulty: (state, action) => {
      state.filterDifficulty = action.payload;
    },
    setFilterType: (state, action) => {
      state.filterType = action.payload;
    },
    
  },
  extraReducers: (builder) => {
    builder
      // Handle fetch Library
      .addCase(getLibrary.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLibrary.fulfilled, (state, action) => {
        state.loading = false;
        state.library = action.payload;
        state.refresh = false;
        state.firstLoading= false;
      })
      .addCase(getLibrary.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.refresh = false;
      })

       // Handle  add
       .addCase(addQuestion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addQuestion.fulfilled, (state, action) => {
        state.loading = false;
        state.refresh = true;
      })
      .addCase(addQuestion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })


      // Handle  remove
      .addCase(removeQuestion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removeQuestion.fulfilled, (state, action) => {
        state.loading = false;
        state.refresh = true;
      })
      .addCase(removeQuestion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })




  },
});
export const { setSearch, setFilterDifficulty, setFilterType } = librarySlice.actions;

export default librarySlice.reducer;
