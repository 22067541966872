import React, { useState, useContext } from "react";
import Select from "react-select";
import { SQContext } from "../../../../../context/superadmin/question";
import MultipleMcq from "./Multiple";
import SingleMcq from "./Single";

const Mcq = () => {
    const [subTypeOptions, setSubTypeOptions] = useState([
        { value: "", label: "Select" },
        { value: "Single Correct Answer", label: "Single Correct Answer" },
        { value: "Multiple Correct Answers", label: "Multiple Correct Answers" },
      ]);
      const [selectedSubType, setSelectedSubType] = useState({ value: "", label: "Select" });
      const { state, setState } = useContext(SQContext);

      const handleSubTypeChange = (selectedOption) => {
        setSelectedSubType(selectedOption);
        setState(prevState => ({
          ...prevState,
          formData: {
            ...prevState.formData,
            subType: selectedOption.value,
            mcq:[{title:"",answer:""}]
          },
        }));
      };
    return (
        <div>
            <div class="form-group">
                <label for="exampleInputConfirmPassword1">Question type</label>
               
                <Select
                    id="skill-select"
                    value={{ value: state.formData.subType, label: state.formData.subType === ""?"Select": state.formData.subType}}
                    onChange={handleSubTypeChange}
                    options={subTypeOptions}
                    className="small text-left"
                    />
                <div className="validation-error mt-2">{state.error?.subType}</div>
            </div>
            { state.formData.subType === "Single Correct Answer" && 
                <SingleMcq />
            }
            { state.formData.subType === "Multiple Correct Answers" && 
              <MultipleMcq />

            }
            
            
        </div>
    );
}

export default Mcq;