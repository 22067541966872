import React from "react";
import Login from "../../components/superadmin/Login";

const Index = () => {

    return (

        <>
            <Login />
        </>

    );

};
export default Index